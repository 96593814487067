import React from 'react';
import Loader from 'components/Loader';
import updateContact from 'api/updateContact';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Box, Button, Modal } from '@mui/material';

dayjs.extend(utc);

interface Props {
    data: { id: number; nextStepScheduledAt: number | null };
}

function NextStepScheduledAtSelect(data: Props) {
    const [nextStepScheduledAt, setNextStepScheduledAt] = React.useState<dayjs.Dayjs | null>(
        data.data.nextStepScheduledAt ? dayjs.utc(data.data.nextStepScheduledAt) : null
    );
    const [tempValue, setTempValue] = React.useState<dayjs.Dayjs | null>(nextStepScheduledAt);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const [isOpen, setIsOpen] = React.useState<boolean>(false);

    const handleOpen = () => {
        setIsOpen(true);
        setTempValue(nextStepScheduledAt);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    const handleChange = (newValue: dayjs.Dayjs | null) => {
        setTempValue(newValue ? newValue.utc() : null);
    };

    const handleApply = async () => {
        setIsLoading(true);
        setNextStepScheduledAt(tempValue);
        await updateContact({
            contactId: data.data.id,
            nextStepScheduledAt: tempValue ? tempValue.valueOf() : null
        });
        setIsLoading(false);
        handleClose();
    };

    const handleClear = () => {
        setTempValue(null);
    };

    if (isLoading) {
        return (
            <div className="relative">
                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 transform">
                    <Loader />
                </div>
            </div>
        );
    }

    const getTextColor = (nextStepScheduledAt: dayjs.Dayjs | null) => {
        return nextStepScheduledAt ? 'text-green-600' : 'text-gray-500';
    };

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <div>
                <button
                    onClick={handleOpen}
                    className={`flex gap-1 rounded ${
                        nextStepScheduledAt ? 'border-2 border-blue-500' : 'border'
                    } border-gray-300 px-1 text-gray-600`}
                >
                    <span className={getTextColor(nextStepScheduledAt)}>
                        {nextStepScheduledAt ? nextStepScheduledAt.utc().format('YYYY-MM-DD HH:mm') : 'Not Scheduled'}
                    </span>
                </button>
                <Modal open={isOpen} onClose={handleClose}>
                    <Box
                        sx={{
                            p: 4,
                            backgroundColor: 'white',
                            margin: 'auto',
                            marginTop: '10%',
                            width: '300px',
                            borderRadius: '8px'
                        }}
                    >
                        <div className="mb-4">
                            <DateTimePicker
                                label="Next Step Datetime (UTC)"
                                value={tempValue}
                                onChange={handleChange}
                                className="w-full"
                            />
                        </div>
                        <div className="flex justify-end gap-2">
                            <Button variant="outlined" color="secondary" onClick={handleClear}>
                                Clear
                            </Button>
                            <Button variant="outlined" color="secondary" onClick={handleClose}>
                                Cancel
                            </Button>
                            <Button variant="contained" color="primary" onClick={handleApply}>
                                Apply
                            </Button>
                        </div>
                    </Box>
                </Modal>
            </div>
        </LocalizationProvider>
    );
}

export default NextStepScheduledAtSelect;
