import httpClient from 'api/http-client';

export interface Response {
    result: string;
}

interface UpdateSequencePayload {
    sequenceId: string;
    aiEnabled?: boolean;
    aiEnabledAutoTakeContact?: boolean;
    autoTakeContactRatio?: number;
}

const updateSequence = async (payload: UpdateSequencePayload): Promise<Response> => {
    const { data } = await httpClient.post<Response>('/api/update-db-sequence', payload);
    return data;
};

export default updateSequence;
