import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import SuperpanelIcon from '../components/icons/SuperpanelIcon';
import login from 'api/login';
import useUserStore from 'stores/user';

function Login() {
    const userStore = useUserStore();
    const [inputs, setInputs] = useState<{ email: string; password: string }>({
        email: '',
        password: ''
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async () => {
        try {
            setLoading(true);
            const userObj = await login(inputs);
            userStore.setState({ user: userObj.user });
            setLoading(false);
            navigate(`/`);
        } catch (error) {
            setLoading(false);
        }
    };

    return (
        <div className="h-screen bg-gray-50">
            <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <SuperpanelIcon className="mx-auto h-14 w-auto" />
                    <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        Sign in to your account
                    </h2>
                </div>
                <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
                    <div className="bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
                        <div className="flex flex-col gap-3">
                            <label htmlFor="name">Email:</label>
                            <input
                                className="rounded border border-gray-400 px-2 py-1"
                                type="email"
                                id="email"
                                name="email"
                                value={inputs.email}
                                onChange={e => {
                                    setInputs(prevInput => ({
                                        ...prevInput,
                                        email: e.target.value
                                    }));
                                }}
                            />

                            <label htmlFor="email">Password:</label>
                            <input
                                className="rounded border border-gray-400 px-2 py-1"
                                type="password"
                                id="password"
                                name="password"
                                value={inputs.password}
                                onChange={e => {
                                    setInputs(prevInput => ({
                                        ...prevInput,
                                        password: e.target.value
                                    }));
                                }}
                            />

                            <button
                                type="button"
                                className="mt-4 rounded-md bg-blue-500 px-4 py-1 text-white hover:bg-blue-600"
                                disabled={loading}
                                onClick={handleSubmit}
                            >
                                Log in
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
