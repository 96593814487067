import React, { useEffect, useState } from 'react';
import { Select, MenuItem, FormControl, InputLabel, SelectChangeEvent } from '@mui/material';
import useContactTableConfigStore from 'stores/contact-table-config';
import Loader from 'components/Loader';
import getSequences, { SequenceArray } from 'api/getSequences';

function SequenceFilter() {
    const [sequences, setSequences] = useState<SequenceArray[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const contactTableConfigStore = useContactTableConfigStore();

    useEffect(() => {
        const fetchSequences = async () => {
            setIsLoading(true);
            try {
                const fetchedSequences = await getSequences();
                setSequences(fetchedSequences);

                if (!contactTableConfigStore.isSequenceSet() && fetchedSequences.length > 0) {
                    const firstSequence = fetchedSequences[0];
                    contactTableConfigStore.updateFilter({
                        colId: 'sequenceId',
                        label: 'Sequence',
                        filterType: 'uuid',
                        id: firstSequence.sequenceId
                    });
                }
                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching sequences:', error);
                setIsLoading(false);
            }
        };
        fetchSequences();
    }, [contactTableConfigStore]);

    const handleSequenceChange = (event: SelectChangeEvent) => {
        const sequenceId = event.target.value;
        contactTableConfigStore.updateFilter({
            colId: 'sequenceId',
            label: 'Sequence',
            filterType: 'uuid',
            id: sequenceId
        });
    };

    if (isLoading) {
        return <Loader />;
    }

    const currentSequenceFilter = contactTableConfigStore.config.filter.find(filter => filter.colId === 'sequenceId');
    let currentSequenceId = null;
    if (currentSequenceFilter?.filterType === 'uuid') {
        currentSequenceId = currentSequenceFilter.id;
    }

    return (
        <FormControl sx={{ minWidth: 120, height: '2rem' }}>
            <InputLabel id="sequence-select-label" sx={{ lineHeight: '2rem', top: '-6px' }}>
                Sequence
            </InputLabel>
            <Select
                labelId="sequence-select-label"
                id="sequence-select"
                value={currentSequenceId || ''}
                label="Sequence"
                onChange={e => handleSequenceChange(e)}
                sx={{ height: '2rem' }}
            >
                {sequences.map(sequence => (
                    <MenuItem key={sequence.sequenceId} value={sequence.sequenceId}>
                        {sequence.sequenceLabel}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}

export default SequenceFilter;
