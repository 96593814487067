import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css';
import { ColDef, SizeColumnsToContentStrategy, GridOptions, SizeColumnsToFitGridStrategy } from 'ag-grid-community';
import Loader from 'components/Loader';
import type { DbContact } from '../../types/dbContact';
import { Switch } from '@mui/material';
import SpinLoader from 'components/ui/spinLoader';
import getContacts, { FilterModelItemInPayload, NORMAL_AI_STATUS } from 'api/getContacts';
import Filter, { FilterModelItem } from './SortAndFilter/Filter';
import Sort from './SortAndFilter/Sort';
import debouncer from 'lib/debouncer';
import useContactTableConfigStore, { SortModelItem } from 'stores/contact-table-config';
import { columnDefinitions } from './contactsTable/colDef';
import getAiConfig from 'api/getAiConfig';
import updateSequence from 'api/updateSequence';
import Tooltip from 'components/Tooltip';

interface ContactsProps {
    currentSequenceId: string | null;
}

function Contacts({ currentSequenceId }: ContactsProps) {
    const [rowData, setRowData] = useState<DbContact[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const contactTableConfigStore = useContactTableConfigStore();

    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [pageInput, setPageInput] = useState('');
    const [totalContacts, setTotalContacts] = useState(0);
    const [intakeStatusArray, setIntakeStatusArray] = useState<string[]>([]);
    const [aiEnabledCompany, setAiEnabledCompany] = useState<boolean>(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [search, setSearch] = useState<null | string>(null);
    const [aiEnabledAutoTakeContact, setAiEnabledAutoTakeContact] = useState<boolean>(false);
    const [autoTakeContactRatio, setAutoTakeContactRatio] = useState<number>(0);
    const [aiEnabledUpdatedReason, setAiEnabledUpdatedReason] = useState<string | null>(null);
    const [columnDefs, setColumnDefs] = useState<ColDef[]>(columnDefinitions);
    const PAGESIZE = 20; // Define the page size

    const fetchContacts = async (
        page: number,
        pageSize: number,
        sortModel: SortModelItem[],
        filterModel: FilterModelItem[]
    ) => {
        setIsLoading(true);
        const sortFields = sortModel.filter(item => item.sort !== null).map(item => item.colId);
        const sortOrders: ('asc' | 'desc')[] = [];
        for (const item of sortModel) {
            if (item.sort !== null) {
                sortOrders.push(item.sort);
            }
        }

        // Combine custom filter with grid filter model
        const combinedFilterModel: FilterModelItemInPayload = filterModel.map(item => {
            if (item.filterType === 'text') {
                return {
                    colId: item.colId,
                    filterType: item.filterType,
                    type: item.type,
                    filter: item.filter
                };
            } else if (item.filterType === 'date') {
                return {
                    colId: item.colId,
                    filterType: item.filterType,
                    type: item.type,
                    dateFrom: item.dateFrom,
                    dateTo: item.dateTo
                };
            } else {
                return {
                    colId: item.colId,
                    filterType: item.filterType,
                    id: item.id
                };
            }
        });

        const res = await getContacts({
            search: search,
            page: page,
            pageSize: pageSize,
            sortFields,
            sortOrders,
            filters: combinedFilterModel
        });
        const contacts = res.result;

        const totalData = res.total;
        setTotalContacts(totalData);
        setTotalPages(Math.ceil(totalData / pageSize));
        setRowData(contacts);
        setAiEnabledCompany(res.aiEnabled);
        setIntakeStatusArray(res.intakeStatusArray);
        setColumnDefs(prev =>
            prev.map(item => {
                if (item.field === 'intakeStatus') {
                    return {
                        ...item,
                        cellRendererParams: { intakeStatusArray: res.intakeStatusArray }
                    };
                } else {
                    return item;
                }
            })
        );
        setAiEnabledAutoTakeContact(res.aiEnabledAutoTakeContact);
        setAutoTakeContactRatio(res.autoTakeContactRatio);
        setAiEnabledUpdatedReason(res.aiEnabledUpdatedReason);
        setIsLoading(false);
    };

    useEffect(() => {
        const checkAndSetSequence = async () => {
            if (!contactTableConfigStore.isSequenceSet()) {
                setIsLoading(true);
                try {
                    const aiConfigs = await getAiConfig();
                    if (aiConfigs.length > 0) {
                        const firstSequenceId = aiConfigs[0].sequenceId;
                        contactTableConfigStore.updateFilter({
                            colId: 'sequenceId',
                            label: 'Sequence',
                            filterType: 'uuid',
                            id: firstSequenceId
                        });
                    }
                    setIsLoading(false);
                } catch (error) {
                    console.error('Error fetching AI configs:', error);
                }
            }
        };

        checkAndSetSequence();
    }, []);

    useEffect(() => {
        if (contactTableConfigStore.isSequenceSet()) {
            fetchContacts(
                currentPage,
                PAGESIZE,
                contactTableConfigStore.config.sort,
                contactTableConfigStore.config.filter
            );
        }
    }, [currentPage, contactTableConfigStore.config.filter, contactTableConfigStore.config.sort, currentSequenceId]);

    if (isLoading) {
        return (
            <div className="flex h-[100vh] w-[100vw] items-center justify-center">
                <Loader />
            </div>
        );
    }

    const handlePrevPage = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePageInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setPageInput(e.target.value);
    };

    const handlePageInputSubmit = () => {
        const pageNumber = parseInt(pageInput, 10);
        if (!isNaN(pageNumber) && pageNumber >= 1 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    const autoSizeStrategy: SizeColumnsToContentStrategy | SizeColumnsToFitGridStrategy = {
        // type: 'fitCellContents'
        type: 'fitGridWidth',
        defaultMinWidth: 150,
        columnLimits: [
            {
                colId: 'firstInteractionAt',
                minWidth: 160
            },
            {
                colId: 'userLastInteractionAt',
                minWidth: 160
            },
            {
                colId: 'aiEnabled',
                minWidth: 120
            },
            {
                colId: 'aiEnabledReplySmsCall',
                minWidth: 120
            },
            {
                colId: 'intakeCompletionScore',
                minWidth: 160
            }
        ]
    };

    const gridOptions: GridOptions = {
        autoSizeStrategy: autoSizeStrategy,
        enableCellTextSelection: true,
        ensureDomOrder: true,
        // defaultColDef: defaultColDef
        getRowStyle: function (params) {
            if (params.data.aiEnabled === false || params.data.intakeCompletionScore === 1) {
                return { 'background-color': '#DCDCDC' };
            }
            return undefined;
        }
    };

    if (!currentSequenceId) {
        return null;
    }

    const onAiEnabledChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        await updateSequence({ sequenceId: currentSequenceId, aiEnabled: event.target.checked });
        setAiEnabledCompany(event.target.checked);
        setIsLoading(false);
    };

    const onAiEnabledAutoTakeContactChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        setIsLoading(true);
        await updateSequence({ sequenceId: currentSequenceId, aiEnabledAutoTakeContact: event.target.checked });
        setAiEnabledAutoTakeContact(event.target.checked);
        setIsLoading(false);
    };

    const onAutoTakeContactRatioChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
        let ratio = parseInt(event.target.value, 10);
        if (isNaN(ratio)) {
            ratio = 0;
        }
        console.log('ratio', ratio);
        if (ratio < 0) ratio = 0;
        if (ratio > 100) ratio = 100;
        if (ratio !== autoTakeContactRatio) {
            debouncer.debounce(
                `update-auto-take-new-contact-ratio`,
                async () => {
                    await updateSequence({ sequenceId: currentSequenceId, autoTakeContactRatio: ratio });
                },
                1000
            );
        }
        setAutoTakeContactRatio(ratio);
    };

    const handleSearch = async () => {
        setIsSubmitting(true);
        setCurrentPage(1); // Reset page to 1 when searching
        await fetchContacts(
            currentPage,
            PAGESIZE,
            contactTableConfigStore.config.sort,
            contactTableConfigStore.config.filter
        );
        setIsSubmitting(false);
    };

    return (
        <div className="h-[calc(100vh-8rem)] w-full">
            <div className="mb-4 flex items-center justify-between">
                <div className="justify-left flex items-center gap-4">
                    <span className="">Total Contacts: {totalContacts}</span>
                    <div className="flex items-center gap-1 rounded-md border border-gray-400 px-2">
                        <Tooltip title={aiEnabledUpdatedReason || 'No reason provided'} placement="top">
                            <span className="font-medium text-blue-400">AI enabled (sequence level):</span>
                        </Tooltip>
                        <Switch
                            checked={aiEnabledCompany}
                            onChange={onAiEnabledChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </div>
                    {aiEnabledCompany && (
                        <div className="flex items-center justify-center gap-4 rounded-md border border-gray-400 px-2">
                            <div className="flex items-center gap-1">
                                <span className="font-medium text-blue-400">AI take new contacts:</span>
                                <Switch
                                    checked={aiEnabledAutoTakeContact}
                                    onChange={onAiEnabledAutoTakeContactChange}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>
                            <div className="flex items-center gap-1">
                                <span className="font-medium text-blue-400">ratio:</span>
                                <input
                                    type="number"
                                    value={autoTakeContactRatio}
                                    onChange={onAutoTakeContactRatioChange}
                                    className={`h-7 w-20 rounded border px-2 py-1 text-center ${aiEnabledAutoTakeContact && autoTakeContactRatio > 0 ? 'bg-green-300' : 'bg-gray-200'}`}
                                    min="0"
                                    max="100"
                                />
                                <span>%</span>
                            </div>
                        </div>
                    )}

                    <div className="">
                        <div>
                            <div className="flex items-center gap-2">
                                <div className="flex h-10 items-center rounded-md border border-gray-400 px-2 py-1">
                                    <input
                                        value={search || ''}
                                        type="text"
                                        placeholder="Search for contacts"
                                        onChange={e => {
                                            if (e.target.value === '') {
                                                setSearch(null);
                                            } else {
                                                setSearch(e.target.value);
                                            }
                                        }}
                                        onKeyDown={async e => {
                                            if (e.key === 'Enter') {
                                                await handleSearch();
                                            }
                                        }}
                                    />
                                </div>
                                <button
                                    type="button"
                                    className="rounded-md border border-gray-300 bg-gray-100 px-2 py-1 text-gray-800"
                                    disabled={isSubmitting}
                                    onClick={handleSearch}
                                >
                                    {isSubmitting && <SpinLoader className="mr-2 h-4 w-4 animate-spin" />}
                                    Search
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-4 flex items-center">
                <Sort
                    sortModels={contactTableConfigStore.config.sort}
                    onSortChange={(colId: string) => {
                        const items = contactTableConfigStore.config.sort.filter(item => item.colId === colId);
                        if (items.length > 0) {
                            const item = items[0];
                            if (item.sort === 'asc') {
                                contactTableConfigStore.updateSort({ ...item, sort: 'desc' });
                            } else if (item.sort === 'desc') {
                                contactTableConfigStore.updateSort({ ...item, sort: null });
                            } else {
                                contactTableConfigStore.updateSort({ ...item, sort: 'asc' });
                            }
                        }
                    }}
                />
            </div>
            <div className="mb-4 flex items-center">
                <Filter
                    filterModel={contactTableConfigStore.config.filter.filter(item => item.colId !== 'sequenceId')}
                    intakeStatusArray={[NORMAL_AI_STATUS, ...intakeStatusArray]}
                    onFilterChange={(
                        colId: string,
                        datetimeFrom: number | null,
                        datetimeTo: number | null,
                        textFilter?: string | null
                    ) => {
                        const items = contactTableConfigStore.config.filter.filter(item => item.colId === colId);
                        if (items.length > 0) {
                            const item = items[0];
                            if (item.filterType === 'date') {
                                contactTableConfigStore.updateFilter({
                                    ...item,
                                    dateFrom: datetimeFrom,
                                    dateTo: datetimeTo
                                });
                            } else if (item.filterType === 'text') {
                                contactTableConfigStore.updateFilter({
                                    ...item,
                                    filter: textFilter || null
                                });
                            } else if (item.filterType === 'uuid') {
                                contactTableConfigStore.updateFilter({
                                    ...item,
                                    id: textFilter || null
                                });
                            }
                        }
                    }}
                />
            </div>
            <div className="h-[calc(100vh-18rem)]">
                <div className="ag-theme-quartz" style={{ height: '100%', width: '100%' }}>
                    <AgGridReact rowData={rowData} columnDefs={columnDefs} gridOptions={gridOptions} />
                </div>
            </div>

            <div className="mt-4 flex items-center justify-end">
                <button
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                    className="mr-2 rounded bg-blue-500 px-4 py-2 text-white disabled:bg-gray-300"
                >
                    Prev Page
                </button>
                <span className="px-4 py-2">
                    Page {currentPage} of {totalPages}
                </span>
                <button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    className="ml-2 rounded bg-blue-500 px-4 py-2 text-white disabled:bg-gray-300"
                >
                    Next Page
                </button>
                <input
                    type="number"
                    value={pageInput}
                    onChange={handlePageInputChange}
                    className="ml-4 w-20 rounded border px-2 py-1" // Adjusted width to make it smaller
                    placeholder="Go"
                />
                <button onClick={handlePageInputSubmit} className="ml-2 rounded bg-green-500 px-4 py-2 text-white">
                    Go
                </button>
            </div>
        </div>
    );
}

export default Contacts;
