import React from 'react';
import { NextStepRule } from 'api/getAiConfig';
import AceEditor from 'react-ace';
import jsYaml from 'js-yaml';
import 'ace-builds/src-noconflict/mode-yaml';
import 'ace-builds/src-noconflict/theme-eclipse';
import { Box } from '@mui/material';

function AutomationRule({ automationRule }: { automationRule: NextStepRule }) {
    const yamlData = automationRule ? jsYaml.dump(automationRule) : '';

    return (
        <Box>
            <AceEditor
                mode="yaml"
                theme="eclipse"
                value={yamlData}
                readOnly={true}
                name="ai-config-editor"
                editorProps={{ $blockScrolling: true }}
                setOptions={{ useWorker: false }}
                width="100%"
                height="500px"
            />
        </Box>
    );
}

export default AutomationRule;
