import React, { useEffect, useState } from 'react';
import getAiConfig, { AiConfig } from 'api/getAiConfig';
import Loader from 'components/Loader';
import AutomationRule from './AiConfig/AutomationRule';
import { Box, FormControl, InputLabel, MenuItem, Modal, Select } from '@mui/material';

function AiConfigComp() {
    const [aiConfigs, setAiConfigs] = useState<AiConfig[]>([]);
    const [isLoading, setIsloading] = useState<boolean>(false);
    const [cur, setCur] = useState<AiConfig | null>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        const fetchAiConfig = async () => {
            setIsloading(true);
            const aiConfigs = await getAiConfig();
            setAiConfigs(aiConfigs);
            setCur(aiConfigs[0]);
            setIsloading(false);
        };
        fetchAiConfig();
    }, []);

    if (isLoading || aiConfigs.length === 0) {
        return (
            <div className="flex h-[100vh] w-[100vw] items-center justify-center">
                <Loader />
            </div>
        );
    }
    return (
        <div className="mb-6 flex flex-col gap-3">
            <div className="mb-4 flex items-center">
                <div className="flex grow items-center gap-2">
                    <button
                        className="flex gap-1 rounded border-2 border-blue-500 px-1 text-gray-600"
                        onClick={() => setIsOpen(true)}
                    >
                        Sequence <span className="text-green-600">{cur?.sequenceLabel}</span>
                    </button>
                </div>
                <Modal open={isOpen} onClose={() => setIsOpen(false)}>
                    <Box
                        sx={{
                            p: 4,
                            backgroundColor: 'white',
                            margin: 'auto',
                            marginTop: '10%',
                            width: '300px',
                            borderRadius: '8px'
                        }}
                    >
                        <FormControl fullWidth variant="outlined">
                            <InputLabel id="uuid-filter-label" shrink>
                                Select Sequence
                            </InputLabel>
                            <Select
                                labelId="uuid-filter-label"
                                value={cur?.sequenceId || ''}
                                onChange={e => {
                                    const selectedConfig = aiConfigs.find(
                                        config => config.sequenceId === e.target.value
                                    );
                                    setCur(selectedConfig || null);
                                }}
                                label="Select Sequence"
                                notched
                            >
                                {aiConfigs.map(sequence => (
                                    <MenuItem key={sequence.sequenceLabel} value={sequence.sequenceId}>
                                        {sequence.sequenceName}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                </Modal>
            </div>
            {cur &&
                cur.nextStepRule.map(rule => (
                    <div key={JSON.stringify(rule)} className="rounded-lg border-2 border-gray-500 px-2 py-1">
                        <AutomationRule automationRule={rule} />
                    </div>
                ))}
        </div>
    );
}

export default AiConfigComp;
