import httpClient from 'api/http-client';

export interface SequenceArray {
    sequenceId: string;
    sequenceName: string;
    sequenceLabel: string;
}

const getSequences = async (): Promise<SequenceArray[]> => {
    const { data } = await httpClient.post<SequenceArray[]>('/api/get-sequences');
    return data;
};

export default getSequences;
