import React from 'react';
import updateContact from 'api/updateContact';
import { FormControl, Select, MenuItem, SelectChangeEvent } from '@mui/material';

interface Props {
    data: { id: number; nextStepStatus: string | null };
}

function NextStepStatusSelect({ data }: Props) {
    const [nextStepStatus, setNextStepStatus] = React.useState<string | null>(data.nextStepStatus);

    const handleChange = async (event: SelectChangeEvent<string>) => {
        const newStatus = event.target.value;
        setNextStepStatus(newStatus);
        await updateContact({
            contactId: data.id,
            nextStepStatus: newStatus as 'scheduled' | 'in-progress'
        });
    };

    return (
        <FormControl size="small">
            <Select
                value={nextStepStatus || ''}
                onChange={handleChange}
                displayEmpty
                sx={{
                    '& .MuiOutlinedInput-notchedOutline': { borderWidth: '1px' },
                    '& .MuiSelect-select': { py: 0.5 } // Reduce padding top and bottom
                }}
            >
                <MenuItem value="scheduled">Scheduled</MenuItem>
                <MenuItem value="in-progress">In Progress</MenuItem>
            </Select>
        </FormControl>
    );
}

export default NextStepStatusSelect;
