import httpClient from 'api/http-client';
import { TransferHistory } from 'types/transferHistory';

export interface Response {
    transferLineHistory: TransferHistory[];
}

const getTransferLines = async (): Promise<Response> => {
    const { data } = await httpClient.post<Response>('/api/transfer-history');
    return data;
};

export default getTransferLines;
