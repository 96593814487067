import httpClient from 'api/http-client';

export type ContactState = {
    firstInteractionTime: number | null;
    firstInteractionType: 'call' | 'sms' | null;
    firstInteractionWithinContactFriendlyTime: boolean | null;
    firstInteractionOutContactFriendlyTimeMorning: boolean | null;
    firstInteractionOutContactFriendlyTimeEvening: boolean | null;
    lastInteractionTime: number | null;
    lastInteractionType: 'call' | 'sms' | null;
    isLastCallAnswered: boolean | null;
    isLastCallEnd: boolean | null;
    userEverReplySms: boolean | null;
    userEverAnswerCall: boolean | null;
    lastUserAnswerCallTime: number | null;
    lastUserSmsTime: number | null;
    lastUserInteractionTime: number | null;
    lastUserInteractionType: 'call' | 'sms' | null;
    userEverInteraction: boolean | null;
    numberOfCalls: number;
    numberOfAssistantSms: number;
    numberOfUserSms: number;
    isSmsOnlyCollectionsMissing: boolean | null;
    lastAssistantSmsTime: number | null;
    lastCallTime: number | null;
    numberOfConsecutiveUndeliveredSms: number;
    isWithinContactFriendlyTime: boolean;
    daysSinceFirstInteraction: number | null;
    userEverInteractionToday: boolean | null;
    currentHourLocal: number | null;
    numberOfCallsToday: number;
    numberOfSmsToday: number;
    numberOfAnsweredCallsToday: number;
    numberOfUserSmsToday: number;
    timeSinceFirstInteraction: number | null;
    timeSinceLastInteraction: number | null;
    timeSinceLastUserInteraction: number | null;
    timeSinceLastAssistantSms: number | null;
    timeSinceLastCall: number | null;
    daysSinceLastCall: number | null;
    daysSinceLastAssistantSms: number | null;
};

export type NextStepRule = {
    conditions: StepRuleTopLevelCondition;
    event: {
        type: 'doCall' | 'doSms' | 'doScheduleCall' | 'doScheduleSms' | 'doUnresponsive';
        params: {
            description: string;
            mode?: 'random';
            endHour?: number;
        };
    };
};
export type StepRuleTopLevelCondition = StepRuleAllConditions | StepRuleAnyConditions | StepRuleNotConditions;

export type StepRuleAllConditions = {
    all: StepRuleNestedCondition[];
    name?: string;
    priority?: number;
};

export type StepRuleAnyConditions = {
    any: StepRuleNestedCondition[];
    name?: string;
    priority?: number;
};

export type StepRuleNotConditions = {
    not: StepRuleNestedCondition;
    name?: string;
    priority?: number;
};

export type StepRuleNestedCondition = StepRuleConditionProperties | StepRuleTopLevelCondition;

export type StepRuleConditionProperties = {
    fact: keyof ContactState;
    operator: string;
    value: { fact: keyof ContactState };
};

export type NextStepRuleParameterDescription = {
    [key in keyof ContactState]: {
        name: string;
        description: string;
    };
};

export interface AiConfig {
    nextStepRuleParameterDescription: NextStepRuleParameterDescription;
    nextStepRule: NextStepRule[];
    sequenceId: string;
    sequenceName: string;
    sequenceLabel: string;
}

const getAiConfig = async (): Promise<AiConfig[]> => {
    const { data } = await httpClient.post<AiConfig[]>('/api/get-ai-config');
    return data;
};

export default getAiConfig;
