import { FilterModelItem } from 'pages/Contacts/SortAndFilter/Filter';
import { mountStoreDevtool } from 'simple-zustand-devtools';
import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';

export type SortOrder = 'asc' | 'desc' | null;

export interface SortModelItem {
    colId: string;
    label: string;
    sort: SortOrder;
}

export type SortFilterConfig = {
    sort: SortModelItem[];
    filter: FilterModelItem[];
};

export interface State {
    config: SortFilterConfig;
    updateSort: (sortConfig: SortModelItem) => void;
    updateFilter: (filterConfig: FilterModelItem) => void;
    isSequenceSet: () => boolean;
}

const INITIAL_CONFIG: SortFilterConfig = {
    sort: [
        {
            colId: 'userEverInteraction',
            label: 'engaged',
            sort: null
        },
        {
            colId: 'userLastInteractionAt',
            label: 'last engaged at',
            sort: null
        },
        {
            colId: 'firstInteractionAt',
            label: 'first contact',
            sort: null
        },
        {
            colId: 'nextStep',
            label: 'next step',
            sort: null
        },
        {
            colId: 'nextStepScheduledAt',
            label: 'next step scheduled at',
            sort: null
        },
        {
            colId: 'aiEnabled',
            label: 'ai follow ups',
            sort: null
        },
        {
            colId: 'aiEnabledReplySmsCall',
            label: 'ai responses',
            sort: null
        },
        {
            colId: 'intakeStatus',
            label: 'intake status',
            sort: null
        },
        {
            colId: 'intakeCompletionScore',
            label: 'intake completion',
            sort: null
        }
    ],
    filter: [
        {
            colId: 'sequenceId',
            label: 'Sequence',
            filterType: 'uuid',
            id: null //default value will be set up as the first value in sequenceIdArray, which is the latest created sequence
        },
        {
            colId: 'userLastInteractionAt',
            label: 'user last interaction at',
            filterType: 'date',
            type: 'inRange',
            dateFrom: null,
            dateTo: null
        },
        {
            colId: 'firstInteractionAt',
            label: 'first contact',
            filterType: 'date',
            type: 'inRange',
            dateFrom: null,
            dateTo: null
        },
        {
            colId: 'nextStepScheduledAt',
            label: 'next step scheduled at',
            filterType: 'date',
            type: 'inRange',
            dateFrom: null,
            dateTo: null
        },
        {
            colId: 'intakeStatus',
            label: 'Intake Status',
            filterType: 'text',
            type: 'contains',
            filter: null
        }
    ]
};

const useContactTableConfigStore = create<State>()(
    persist(
        (set, get) => ({
            config: INITIAL_CONFIG,
            updateSort: (sortConfig: SortModelItem) => {
                set(state => {
                    const newSort = state.config.sort.map(item => {
                        if (item.colId === sortConfig.colId) {
                            return sortConfig;
                        }
                        return item;
                    });
                    return { config: { ...state.config, sort: newSort } };
                });
            },
            updateFilter: (filterConfig: FilterModelItem) => {
                set(state => {
                    const newFilter = state.config.filter.map(item => {
                        if (item.colId === filterConfig.colId) {
                            return filterConfig;
                        }
                        return item;
                    });
                    return { config: { ...state.config, filter: newFilter } };
                });
            },
            isSequenceSet: () => {
                const state = get();
                const sequenceFilter = state.config.filter.find(filter => filter.colId === 'sequenceId');
                return (
                    sequenceFilter !== undefined && sequenceFilter.filterType === 'uuid' && sequenceFilter.id !== null
                );
            }
        }),
        {
            name: 'table-config-store',
            storage: createJSONStorage(() => localStorage),
            // if browser->application->localstorage->table-config-store version is not this version number, the migrate function will be called
            version: 4,
            migrate: async (persistedState, version) => {
                console.log('version', version);
                const temp = { ...(persistedState as State) };
                if (version === 3) {
                    // clear filter
                    temp.config = INITIAL_CONFIG;
                }
                console.log('temp', temp);
                return temp;
            },
            partialize: state => ({
                config: state.config
            })
        }
    )
);

if (process.env.NODE_ENV === 'development') {
    mountStoreDevtool('ContactTableConfig', useContactTableConfigStore);
}
export default useContactTableConfigStore;
